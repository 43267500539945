import classNames from 'classnames';
import dayjs from 'dayjs';
import Button from 'modules/common/components/Button';
import CountdownDate from 'modules/common/components/CountdownDate';
import { Link } from 'modules/i18n';
import useUser from 'modules/User/hooks/useUser';
import React from 'react';
import { FaGift } from 'react-icons/fa';
import { MdAdd, MdRemoveCircle } from 'react-icons/md';
import PlanBadge from './PlanBadge';
import Tooltip from 'rc-tooltip';
import useSubscription from 'modules/Billing/hooks/useSubscription';

type PricingProps = React.HTMLAttributes<HTMLDivElement> & {
  withSubscribeButton?: boolean;
};

const endPromoDate = new Date('2025-04-15 23:59:59');

const PricingItem = ({
  plan,
  description,
  price,
  priceSmall,
  features,
  warranty,
  highlighted,
  action,
  bonuses,
  bonusTitle,
}: {
  plan: React.ComponentProps<typeof PlanBadge>['type'];
  description?: React.ReactNode;
  price: React.ReactNode;
  priceSmall: React.ReactNode;
  warranty?: React.ReactNode;
  action?: React.ReactNode;
  highlighted?: boolean;
  features: { title: React.ReactNode; included?: boolean }[];
  bonuses?: { title: React.ReactNode; price?: number }[];
  bonusTitle?: string;
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border  shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white',
        {
          'border-gray-100': !highlighted,
          'border-primary': highlighted,
        }
      )}
    >
      <div className="flex-1 flex flex-col gap-2">
        <h3 className="text-2xl font-semibold gap-2 flex items-center justify-center">
          {plan === 'none' ? (
            <PlanBadge type={'none'} label="Sans Headlinker" />
          ) : (
            <>
              <span>Headlinker</span>
              <PlanBadge type={plan} />
            </>
          )}
        </h3>
        <div className="flex justify-center items-baseline my-2">
          <span className="mr-2 text-5xl font-extrabold">{price}</span>
          <span className="text-gray-500 dark:text-gray-400">{priceSmall}</span>
        </div>
        <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400 [&>strong]:text-black mb-2">{description}</p>
        <ul role="list" className="mb-8 space-y-4 text-left flex-1">
          {features.map((feature, index) => (
            <li key={`feature_${index}`} className="flex items-start space-x-3">
              {feature.included ? (
                <MdAdd className="fill-success mt-0.5 w-5 h-5 flex-shrink-0" />
              ) : (
                <MdRemoveCircle className="fill-error mt-0.5 w-5 h-5 flex-shrink-0" />
              )}
              <span>{feature.title}</span>
            </li>
          ))}
        </ul>
        {bonuses && (
          <div>
            <h4 className="mb-4 text-lg font-semibold text-left">
              {bonusTitle} <span className="text-primary font-extrabold">{bonuses?.reduce((acc, bonus) => acc + (bonus.price || 0), 0)}€</span>
            </h4>
            <ul className="mb-8 space-y-4 text-left">
              {bonuses.map((bonus, index) => (
                <li key={`bonus_${index}`} className="flex items-start space-x-3">
                  <FaGift className="fill-primary mt-0.5 w-5 h-5 flex-shrink-0" />
                  <span className="flex-1">{bonus.title}</span>
                  {bonus.price && <span className="w-12 text-right text-lg font-bold line-through">{bonus.price}€</span>}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div>{warranty}</div>
      </div>
      {action && <div className="mt-5">{action}</div>}
    </div>
  );
};

const sansEngagement = (
  <Tooltip
    placement="top"
    trigger={['hover', 'click']}
    overlayClassName="opacity-100"
    mouseLeaveDelay={0.3}
    overlay={<div className="p-5 z-20 text-sm space-y-4 max-w-[90vw]">Résiliez quand vous le souhaitez.</div>}
  >
    <strong className="text-xs block mt-2 underline decoration-dotted">Sans engagement 🎉</strong>
  </Tooltip>
);
export const PricingMembership: React.FC<PricingProps> = ({ withSubscribeButton = true }: { withSubscribeButton?: boolean }) => {
  const { user, hasPermission } = useUser();
  const { deleteSubscription, deletingSubscription } = useSubscription();

  return (
    <div className="mt-5">
      <PricingItem
        plan="starter"
        price="150€"
        priceSmall="/an"
        description={<>Adhésion annuelle à la communauté Headlinker</>}
        features={[
          {
            title: (
              <>
                <strong>Accès immédiat à la plateforme exclusive</strong> pour partager vos missions et candidats
              </>
            ),
            included: true,
          },
          {
            title: (
              <>
                <strong>Invitation aux cafés virtuels</strong> tous les vendredis
              </>
            ),
            included: true,
          },
          {
            title: (
              <>
                <strong>Invitation aux apéros en présentiel</strong> une fois par mois (Paris, Nantes, Bordeaux, Toulouse, Aix/Marseille, Bruxelles,
                Québec... et bientôt Lille, Luxembourg, Lyon, Rennes)
              </>
            ),
            included: true,
          },
          {
            title: (
              <>
                <strong>Accès immédiat à la communauté privée Slack</strong>
              </>
            ),
            included: true,
          },
          {
            title: (
              <>
                <strong>Fees Headlinker : 10% du montant de la prime</strong> en cas de recrutement réussi via la plateforme
              </>
            ),
            included: true,
          },
        ]}
        warranty={
          <>
            <strong>Pas satisfait?</strong> On te rembourse sans condition.
            {sansEngagement}
          </>
        }
        bonusTitle="Bonus offert à l'inscription :"
        bonuses={[
          {
            title: (
              <>
                <strong>1 liste de prospection sur mesure (30 noms)</strong>
              </>
            ),
            price: 60,
          },
          {
            title: (
              <>
                <strong>1 mois de sessions de prospection gratuites</strong> (2 par semaine)
                <br />
                <small>
                  ➡️ Au-delà du 1er mois, les sessions sont à <strong>20€ l’unité</strong>
                </small>
              </>
            ),
            price: 160,
          },
        ]}
        action={
          withSubscribeButton && hasPermission('member') ? (
            <>
              <strong>Plan actuel</strong>
              <Button
                className="btn btn-error w-full"
                onClick={() => deleteSubscription({ subscriptionId: 'notimplemented' })}
                loading={deletingSubscription}
                disabled={!user.roles.includes('member')}
              >
                Annuler l’abonnement
              </Button>
            </>
          ) : null
        }
      />
    </div>
  );
};

const Pricing: React.FC<PricingProps> = ({ className, withSubscribeButton = true, ...props }) => {
  const { hasPermission, user } = useUser();
  const { createSubscription, loadingSubscription, deleteSubscription, deletingSubscription } = useSubscription();

  return (
    <div className="mt-5">
      <PricingMembership />
      <h2 className="text-2xl font-semibold my-5 text-center">Et une fois membre</h2>
      <div className={classNames('space-y-8 lg:grid sm:gap-6 xl:gap-10 lg:space-y-0 lg:grid-cols-3', className)} {...props}>
        <PricingItem
          plan="booster"
          price={dayjs().isAfter(dayjs(endPromoDate)) ? '59€' : '29€'}
          priceSmall="/mois"
          features={[
            {
              title: (
                <>
                  Sessions de{' '}
                  <Tooltip
                    placement="bottom"
                    trigger={['hover', 'click']}
                    overlayClassName="opacity-100"
                    mouseLeaveDelay={0.3}
                    overlay={
                      <div className="p-5 z-20 text-sm space-y-4 max-w-[90vw]">
                        <div>
                          <h4 className="text-2xl font-semibold text-gray-900 mb-4">Qu’est-ce que les sessions de prospection ?</h4>
                          <p className="text-gray-600 mb-2">2 fois par semaine, nos recruteurs se retrouvent pour :</p>
                          <ul className="space-y-2 text-gray-600">
                            <li>🔹 Salle Cold Call : Appels en binôme</li>
                            <li>🔹 Salle de prospection écrite : Rédaction en équipe</li>
                          </ul>
                        </div>
                        <div>
                          <h4 className="text-2xl font-semibold text-gray-900 mb-4">Pourquoi ces sessions sont-elles efficaces ?</h4>
                          <ul className="space-y-2 text-gray-600">
                            <li>✅ Créneau bloqué dans votre agenda</li>
                            <li>✅ Bonne ambiance et émulation collective</li>
                            <li>✅ Apprentissage des meilleures pratiques</li>
                          </ul>
                        </div>
                      </div>
                    }
                  >
                    <span className="text-booster cursor-help font-bold underline decoration-dotted">prospection</span>
                  </Tooltip>{' '}
                  <strong>illimitées</strong>
                </>
              ),
              included: true,
            },
          ]}
          warranty={
            <>
              {!dayjs().isAfter(dayjs(endPromoDate)) ? (
                <div className="bg-gray-50 p-2 rounded-lg animate-[pulse_1.5s_infinite]">
                  <CountdownDate type="countdown-simple" className="text-accent font-bold justify-center flex gap-3 lowercase" date={endPromoDate} />
                  valable jusqu’au <strong>31 mars 2025</strong>
                  <br />
                  puis <strong>59€</strong> /mois{' '}
                </div>
              ) : (
                <>👍 Tu choisis les sessions qui te conviennent. Sans limite.</>
              )}
              {sansEngagement}
            </>
          }
          action={
            withSubscribeButton ? (
              hasPermission('booster') ? (
                <>
                  {hasPermission('teambooster') ? (
                    <>
                      <Button className="btn w-full" disabled>
                        Plus besoin
                      </Button>
                    </>
                  ) : (
                    <>
                      <strong>Plan actuel</strong>
                      <Button
                        className="btn btn-error w-full"
                        onClick={() => deleteSubscription({ subscriptionId: 'notimplemented' })}
                        loading={deletingSubscription}
                        disabled={!user.roles.includes('booster')}
                      >
                        Annuler l’abonnement
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <Button
                  className="btn bg-booster w-full"
                  onClick={() => createSubscription({ plan: 'booster_monthly_59', coupon: 'EARLYBOOSTER' })}
                  loading={loadingSubscription}
                >
                  Souscrire
                </Button>
              )
            ) : null
          }
        />
        <PricingItem
          plan="teambooster"
          price={'129€'}
          highlighted
          priceSmall="/mois"
          features={[
            {
              title: (
                <>
                  <strong>Tout</strong> dans l’offre <strong className="text-booster">Booster</strong>
                </>
              ),
              included: true,
            },
            {
              title: (
                <>
                  Intégration à une{' '}
                  <Tooltip
                    placement="bottom"
                    trigger={['hover', 'click']}
                    overlayClassName="opacity-100"
                    mouseLeaveDelay={0.3}
                    overlay={
                      <div className="p-5 z-20 text-sm space-y-4 max-w-[90vw]">
                        <div>
                          <h3 className="font-bold text-lg mb-1">Votre équipe sur mesure</h3>
                          <ul className="list-disc pl-5 space-y-1">
                            <li>
                              Vous intégrez une équipe de <strong>5 recruteurs</strong> soigneusement sélectionnée par <strong>Dorothée</strong> en
                              fonction de votre personnalité.
                            </li>
                            <li>Vous évoluez avec des partenaires qui partagent votre ambition, pour des échanges fluides et efficaces.</li>
                          </ul>
                        </div>
                        <div>
                          <h3 className="font-bold text-lg mb-1">L’accès aux insights de Dorothée</h3>
                          <ul className="list-disc pl-5 space-y-1">
                            <li>
                              Chaque semaine, profitez d’une session où Dorothée partage les <strong>meilleures techniques</strong> issues des
                              échanges avec plus de <strong>650 recruteurs</strong>.
                            </li>
                            <li>
                              Vous gagnez du temps en découvrant des méthodes qui fonctionnent déjà, et que vous pouvez appliquer immédiatement.
                            </li>
                          </ul>
                        </div>
                        <div>
                          <h3 className="font-bold text-lg mb-1">Un groupe WhatsApp privé</h3>
                          <ul className="list-disc pl-5 space-y-1">
                            <li>
                              Un espace d’échange ouvert 24/7 pour partager vos réussites, vos problématiques ou demander conseil en temps réel.
                            </li>
                            <li>Vous n’êtes jamais seul face à vos défis : votre équipe et Dorothée sont là pour vous.</li>
                          </ul>
                        </div>
                        <p className="italic text-sm">
                          💬 Une combinaison unique d’entraide, de conseils concrets et de soutien collectif pour accélérer vos résultats.
                        </p>
                      </div>
                    }
                  >
                    <span className="text-teambooster underline decoration-dotted cursor-help">
                      <strong>équipe dédiée</strong>
                    </span>
                  </Tooltip>
                </>
              ),
              included: true,
            },
            {
              title: (
                <>
                  Accès aux <strong>insights exclusifs</strong> de Dorothée
                </>
              ),
              included: true,
            },
            {
              title: (
                <>
                  Groupe WhatsApp <strong>privé</strong>
                </>
              ),
              included: true,
            },
          ]}
          warranty={
            <>
              👍 <strong>Le meilleur choix</strong>. Jette un oeil aux{' '}
              <Link className="link link-primary" href="/?testimonial-offer=teambooster#testimonials">
                témoignages
              </Link>
              .{sansEngagement}
            </>
          }
          action={
            withSubscribeButton ? (
              hasPermission('teambooster') ? (
                <>
                  {hasPermission('pro') ? (
                    <>
                      <Button className="btn w-full" disabled>
                        Plus besoin
                      </Button>
                    </>
                  ) : (
                    <>
                      <strong>Plan actuel</strong>
                      <Button
                        className="btn btn-error w-full"
                        onClick={() => deleteSubscription({ subscriptionId: 'notimplemented' })}
                        loading={deletingSubscription}
                        disabled={!user.roles.includes('teambooster')}
                      >
                        Annuler l’abonnement
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <Button
                  className="btn bg-teambooster w-full"
                  onClick={() => createSubscription({ plan: 'teambooster_monthly_129' })}
                  loading={loadingSubscription}
                >
                  Souscrire
                </Button>
              )
            ) : null
          }
        />
        <PricingItem
          plan="pro"
          price="329€"
          priceSmall="/mois"
          features={[
            {
              title: (
                <>
                  <strong>Tout</strong> dans l’offre <strong className="text-teambooster">Team Booster</strong>
                </>
              ),
              included: true,
            },
            {
              title: (
                <>
                  <strong>0 frais Headlinker</strong> sur tous tes placements
                </>
              ),
              included: true,
            },
          ]}
          warranty={
            <>
              💪 <strong>Beaucoup d‘activité ?</strong> On te permet de maîtriser tes coûts.{sansEngagement}
            </>
          }
          action={
            withSubscribeButton ? (
              hasPermission('pro') ? (
                <>
                  <strong>Plan actuel</strong>
                  <Button
                    className="btn btn-error w-full"
                    onClick={() => deleteSubscription({ subscriptionId: 'notimplemented' })}
                    loading={deletingSubscription}
                  >
                    Annuler l’abonnement
                  </Button>
                </>
              ) : (
                <Button className="btn bg-pro w-full" onClick={() => createSubscription({ plan: 'pro_monthly_329' })} loading={loadingSubscription}>
                  Souscrire
                </Button>
              )
            ) : null
          }
        />
      </div>
    </div>
  );
};

export default Pricing;
