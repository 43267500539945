import React from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';

import useCountdownDate from 'modules/common/hooks/useCountdownDate';
import { useToggle } from '@react-hookz/web';
import { useTranslation } from 'modules/i18n';

type CountdownDateProps = {
  date?: Date;
  type?: 'countdown-simple' | 'countdown-vertical';
  onFinished?: () => any;
} & React.HTMLAttributes<HTMLDivElement>;

const CountdownDate: React.FC<CountdownDateProps> = ({ children, type = 'countdown-simple', date, className, onFinished, ...props }) => {
  const { days, hours, minutes, seconds } = useCountdownDate(dayjs(date).toDate());
  const [onFinishedCalled, callOnFinished] = useToggle(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (date && days === 0 && hours === 0 && minutes === 0 && seconds === 0 && onFinished && !onFinishedCalled) {
      callOnFinished();
      onFinished();
    }
  }, [date, days, hours, minutes, seconds, onFinished, onFinishedCalled, callOnFinished]);

  if (!date) {
    return <span className="loader" />;
  }

  const shortSuffix = type === 'countdown-simple' ? '.short' : '';

  return (
    <span className={classNames(type, className)} {...props}>
      <span>
        <span suppressHydrationWarning>{days}</span>
        <span>{t(`common.calendar:days${shortSuffix}`)}</span>
      </span>
      <span>
        <span suppressHydrationWarning>{hours}</span>
        <span>{t(`common.calendar:hours${shortSuffix}`)}</span>
      </span>
      <span>
        <span suppressHydrationWarning>{minutes}</span>
        <span>{t(`common.calendar:minutes${shortSuffix}`)}</span>
      </span>
      <span>
        <span suppressHydrationWarning>{seconds}</span>
        <span>{t(`common.calendar:seconds${shortSuffix}`)}</span>
      </span>
    </span>
  );
};

export default CountdownDate;
