import { useState, useEffect } from 'react';

interface TimeRemaining {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  inPast: boolean;
  inFuture: boolean;
  distance: number;
}

const getValuesFromDate = (targetDate: Date) => {
  const now = new Date();
  const distance = targetDate.getTime() - now.getTime();

  const inPast = distance <= 0;
  const inFuture = distance > 0;

  const days = Math.floor(Math.abs(distance) / (1000 * 60 * 60 * 24));
  const hours = Math.floor((Math.abs(distance) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((Math.abs(distance) % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((Math.abs(distance) % (1000 * 60)) / 1000);

  return {
    distance,
    days,
    hours,
    minutes,
    seconds,
    inPast,
    inFuture,
  };
};

function useCountdownDate(targetDate: Date): TimeRemaining {
  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>(getValuesFromDate(targetDate));

  useEffect(() => {
    const interval = setInterval(() => {
      const values = getValuesFromDate(targetDate);
      setTimeRemaining(values);

      if (values.distance < 0) {
        clearInterval(interval);
        setTimeRemaining({
          ...values,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return timeRemaining;
}

export default useCountdownDate;
